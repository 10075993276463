import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import { transformWorkout } from "@helpers/favouritesHelper"
import CardsContainer from "@global/cards/CardsContainer"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Block from "@global/layout/Block"
import Card from "@global/cards/Card"

const WorkoutCategories = ({ data, pageContext: { title, parent } }) => {
  const workouts = data.workouts
  const content = data.content.newWorkouts
  return (
    <Layout parent={parent} title={title}>
      <HelmetDatoCms
        defer={false}
        title={`${title}${data.seo.globalSeo.titleSuffix}`}
      />
      <Block padding="double-top" gutters={true} maxWidth="inner">
        <h1>{content.title}</h1>
        <p>{content.caption}</p>
      </Block>
      <Block gutters={true} padding="both" maxWidth="list-inner">
        <CardsContainer>
          {workouts.nodes.map(node => (
            <Card
              {...transformWorkout(node)}
              modifier="horizontal-for-small"
              key={`workoutCat-${node.title}`}
            />
          ))}
        </CardsContainer>
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query newestWorkouts {
    workouts: allDatoCmsWorkout(
      limit: 24
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      newWorkouts {
        title
        caption
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`

export default WorkoutCategories
